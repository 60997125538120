<template>
    <div class="grid-container portfolio-teaser-group">
        <div class="grid-x grid-margin-x align-center-middle">
            <div class="cell small-12" v-show="!loadingInProgress">
                <div class="portfolio-teaser__grid grid-x grid-margin-x">
                    <div
                        class="cell small-12 medium-6 xlarge-4 xxlarge-3"
                        v-for="index in portfolioTeaserCounts[teaserGroupId]"
                        :key="index"
                    >
                        <portfolio-teaser-skeleton></portfolio-teaser-skeleton>
                    </div>
                </div>
            </div>

            <div
                class="cell small-12"
                v-show="portfolioTeasersLoaded[teaserGroupId] || portfolioTeaserCounts[teaserGroupId] > maxTeasers"
            >
                <div class="grid-x grid-margin-x">
                    <div
                        class="cell small-12 large-6 xlarge-4 xxlarge-3 portfolio-teaser-wrapper"
                        v-for="(teaser, index) in teasers"
                        :key="index"
                    >
                        <portfolio-teaser :portfolio="teaser" :teaser-group-id="teaserGroupId"></portfolio-teaser>
                    </div>
                </div>
            </div>

            <div
                class="cell shrink portfolio-teaser__grid"
                v-show="
                    (portfolioTeasersLoaded[teaserGroupId] || portfolioTeaserCounts[teaserGroupId] > maxTeasers) &&
                    portfolioTeaserCounts[teaserGroupId] < portfolioTeasers.length
                "
            >
                <div class="portfolio-teaser-group__load-more">
                    <a
                        href="#"
                        @click.prevent="loadMore"
                        class="mdc-button mdc-button--unelevated mdc-button--secondary align-self-middle"
                    >
                        <div class="mdc-button__ripple"></div>
                        <i class="icon icon--NavigationLongArrowRight mdc-button__icon" aria-hidden="true"></i>
                        <span class="mdc-button__label">{{ loadMoreButtonLabel }}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import PortfolioTeaser from "../../molecules/portfolio-teaser/PortfolioTeaser.vue";
import PortfolioTeaserSkeleton from "../../molecules/portfolio-teaser-skeleton/PortfolioTeaserSkeleton.vue";

export default {
    name: "portfolio-teaser-group",
    components: {
        PortfolioTeaser,
        PortfolioTeaserSkeleton,
    },
    props: {
        portfolioTeasers: {
            type: Array,
            default: () => [],
            required: true,
        },
        maxTeasers: {
            type: Number,
            default: 4,
        },
        loadMoreButtonLabel: {
            type: String,
            default: "Mehr laden",
        },
        teaserGroupId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            teasers: [],
            teaserGroups: [],
            loadedGroups: 0,
            teasersLoaded: false,
            loadingInProgress: false,
        };
    },

    computed: {
        ...mapGetters("teaserStore", ["portfolioTeaserCounts", "portfolioTeasersLoaded", "loadedPortfolioTeasers"]),
    },

    methods: {
        ...mapActions("teaserStore", ["setPortfolioTeaserCount", "initPortfolioTeaserGroups"]),

        loadTeasers() {
            if (this.loadedGroups > 0) {
                this.setPortfolioTeaserCount({
                    id: this.teaserGroupId,
                    count: (this.portfolioTeaserCounts[this.teaserGroupId] +=
                        this.teaserGroups[this.loadedGroups].length),
                });
            }

            this.teaserGroups[this.loadedGroups].forEach((teaser, index) => {
                if (index < this.maxTeasers) {
                    //create new teaser
                    this.teasers.push(teaser);
                }
            });
        },

        createTeaserChunks() {
            return new Promise((resolve) => {
                let counter = -1;

                this.teaserGroups = this.portfolioTeasers
                    .map((teaser, index, array) => {
                        if (index % this.portfolioTeaserCounts[this.teaserGroupId] === 0) {
                            counter += 1;

                            return array.slice(
                                counter * this.portfolioTeaserCounts[this.teaserGroupId],
                                this.portfolioTeaserCounts[this.teaserGroupId] +
                                    counter * this.portfolioTeaserCounts[this.teaserGroupId]
                            );
                        }
                    })
                    .filter((chunk) => {
                        return chunk && chunk.length;
                    });

                resolve();
            });
        },

        loadMore() {
            this.loadedGroups += 1;
            this.loadTeasers();
        },
    },

    async beforeMount() {
        const teaserCount =
            this.portfolioTeasers.length <= this.maxTeasers ? this.portfolioTeasers.length : this.maxTeasers;
        this.setPortfolioTeaserCount({ id: this.teaserGroupId, count: teaserCount });
        this.initPortfolioTeaserGroups(this.teaserGroupId);
        await this.createTeaserChunks();

        this.loadTeasers();
    },

    watch: {
        portfolioTeasersLoaded: {
            deep: true,
            handler() {
                if (
                    this.portfolioTeasersLoaded[this.teaserGroupId] &&
                    this.portfolioTeaserCounts[this.teaserGroupId] <= this.maxTeasers
                ) {
                    this.loadingInProgress = true;
                }
            },
        },
    },
};
</script>
