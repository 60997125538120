<template>
    <div class="portfolio-teaser grid-x align-top cursor-pointer" @click="goToPortfolioLink()">
        <div class="cell">
            <div class="portfolio-teaser__img-wrapper">
                <img :src="portfolio.img" :alt="portfolio.title" class="portfolio-teaser__img" @load="onImgLoad()" />
            </div>
            <div class="portfolio-teaser__content-copy">
                <h2 class="portfolio-teaser__title">{{ shortenedTitle(portfolio.title) }}</h2>
                <p class="portfolio-teaser__text">{{ shortenedDescription(portfolio.text) }}</p>
            </div>
        </div>
        <div class="cell align-self-bottom">
            <a class="mdc-button portfolio-teaser__link" ref="cardLink" :href="portfolio.link">
                <div class="mdc-button__ripple"></div>
                <span class="mdc-button__label">{{ portfolio.linkLabel }}</span>
            </a>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "portfolio-teaser",

    props: {
        portfolio: {
            type: Object,
            required: true,
        },

        teaserGroupId: {
            type: String,
            required: true,
        },
    },

    methods: {
        ...mapActions({
            increaseLoadedPortfolio: "teaserStore/increaseLoadedPortfolio",
        }),

        onImgLoad() {
            this.increaseLoadedPortfolio(this.teaserGroupId);
        },

        shortenedTitle(title) {
            if (title.length <= 70) {
                return title;
            }

            let cutTitle = title.slice(0, 70);

            let lastSpaceIndex = cutTitle.lastIndexOf(" ");
            if (lastSpaceIndex !== -1) {
                return `${cutTitle.slice(0, lastSpaceIndex)}...`;
            }

            return `${cutTitle}...`;
        },

        shortenedDescription(description) {
            if (description.length <= 190) {
                return description;
            }

            let cutDescription = description.slice(0, 190);

            let lastSpaceIndex = cutDescription.lastIndexOf(" ");
            if (lastSpaceIndex !== -1) {
                return `${cutDescription.slice(0, lastSpaceIndex)}...`;
            }

            return `${cutDescription}...`;
        },

        goToPortfolioLink() {
            window.location.href = this.portfolio.link;
        },
    },
};
</script>
