<template>
    <div class="portfolio-teaser portfolio-teaser--skeleton">
        <div class="portfolio-teaser--skeleton-img"></div>
        <div class="portfolio-teaser--skeleton-title"></div>
        <div class="portfolio-teaser--skeleton-text"></div>
        <div class="portfolio-teaser--skeleton-btn"></div>
    </div>
</template>

<script>
export default {
    name: "portfolio-teaser-skeleton",
};
</script>
